import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

import Icon from "./Icon";

import {
  SizeRestrictionResultStatus,
  VoyageValuesType,
} from "@copmer/calculator-widget";

export const SizeRestrictionWarning = ({
  sizeRestriction,
  portLabel,
  className,
}: {
  portLabel?: string;
  className?: string;
  sizeRestriction:
    | {
        portCallIndex: number;

        status: SizeRestrictionResultStatus | undefined;

        // The reason why the voyage is not within size restrictions
        reason: string | null;
      }
    | undefined;
}) => {
  if (!sizeRestriction) {
    return null;
  }

  if (
    !sizeRestriction.status ||
    sizeRestriction.status === SizeRestrictionResultStatus.OK
  ) {
    return null;
  }

  if (
    sizeRestriction.status === SizeRestrictionResultStatus.Error ||
    sizeRestriction.status === SizeRestrictionResultStatus.Critical
  ) {
    const tooltip =
      sizeRestriction.reason ?? `Size restriction ${sizeRestriction.status}`;

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className={className}>
            <Icon
              icon="InfoSquareRounded"
              className={cn("h-4 w-4 ml-1", {
                "text-cviWarning":
                  sizeRestriction.status === SizeRestrictionResultStatus.Error,
                "text-destructive":
                  sizeRestriction.status ===
                  SizeRestrictionResultStatus.Critical,
              })}
            />
          </TooltipTrigger>
          <TooltipContent className="bg-frGrey-800 border-frGrey-500 max-w-60 font-normal text-sm">
            <p>{portLabel ? `${portLabel}: ${tooltip}` : tooltip}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return null;
};

export const SizeRestrictionItineraryWarning = ({
  voyage,
}: {
  voyage: VoyageValuesType;
}) => {
  const { portInfos, sizeRestrictions } = voyage;

  const criticalWarning = sizeRestrictions.find(
    (restriction) => restriction.status === SizeRestrictionResultStatus.Critical
  );

  if (criticalWarning) {
    const portLabel = portInfos[criticalWarning.portCallIndex].DisplayName;

    return (
      <SizeRestrictionWarning
        sizeRestriction={criticalWarning}
        portLabel={portLabel}
        className="align-bottom"
      />
    );
  }

  const errorWarning = sizeRestrictions.find(
    (restriction) => restriction.status === SizeRestrictionResultStatus.Error
  );

  if (errorWarning) {
    const portLabel = portInfos[errorWarning.portCallIndex].DisplayName;

    return (
      <SizeRestrictionWarning
        sizeRestriction={errorWarning}
        portLabel={portLabel}
        className="align-bottom"
      />
    );
  }
};
