import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type CalculationCopyItem = {
  calculationId: string;
  planId: string;
  month: string;
};

export interface CalculatorCopyContextType {
  isLoading: boolean;
  activeMonth: string;
  calculationsToCopy: CalculationCopyItem[];

  updateCopyData: (
    isLoading: boolean,
    activeMonth: string,
    data: CalculationCopyItem[]
  ) => void;
}

export const CalculatorCopyContext = createContext<CalculatorCopyContextType>({
  isLoading: false,
  calculationsToCopy: [],
  activeMonth: new Date().toISOString(),

  updateCopyData: () => {},
});

export const useCalculatorCopyContext = () => useContext(CalculatorCopyContext);

export const useUpdateCopyData = () => {
  const ctx = useCalculatorCopyContext();

  if (!ctx) {
    throw new Error(
      "useUpdateCopyData must be used within a CalculatorCopyContextProvider"
    );
  }

  const { updateCopyData } = ctx;

  return updateCopyData;
};

export const CalculatorCopyContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeMonth, setActiveMonth] = useState(new Date().toISOString());

  const [calculationsToCopy, setCalculationsToCopy] = useState<
    CalculationCopyItem[]
  >([]);

  const updateCopyData = useCallback(
    (newLoading: boolean, newMonth: string, data: CalculationCopyItem[]) => {
      setIsLoading(newLoading);
      setCalculationsToCopy(data);
      setActiveMonth(newMonth);
    },
    [setIsLoading, setCalculationsToCopy, setActiveMonth]
  );

  const value = useMemo(
    () => ({
      isLoading,
      activeMonth,
      calculationsToCopy,
      updateCopyData,
    }),
    [isLoading, activeMonth, calculationsToCopy, updateCopyData]
  );

  return (
    <CalculatorCopyContext.Provider value={value}>
      {children}
    </CalculatorCopyContext.Provider>
  );
};
