import Select from "react-select-virtualized";
import { DropdownIndicatorProps } from "react-select";

import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import Icon from "./Icon";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useMemo } from "react";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { cva } from "class-variance-authority";

interface InputWrapperProps {
  label: string | React.ReactNode;
  children: React.ReactNode;

  error?: string;
  isRequired?: boolean;
  disabled?: boolean;
  id?: string;
  tooltip?: string;

  className?: string;
  labelClassName?: string;

  plainLabel?: boolean;
}

export const InputWrapper = ({
  label,
  children,
  isRequired,
  error,
  id,
  disabled,
  tooltip,
  labelClassName,
  className,
  plainLabel,
}: InputWrapperProps) => {
  let labelText = label;

  if (!plainLabel && typeof label === "string") {
    labelText = isRequired ? `${label} *` : `${label} (optional)`;
  }

  const labelProps = id ? { htmlFor: id } : {};

  return (
    <div className={cn("flex flex-col w-full gap-1 -mt-1", className)}>
      <Label
        variant="body3"
        className={cn(
          "flex gap-1 items-center text-xs",
          {
            "text-frGrey-400": !error,
            "text-frGrey-500": disabled,
            "text-error": error,
          },
          labelClassName
        )}
        {...labelProps}
      >
        {labelText}

        {tooltip && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger type="button" tabIndex={-1}>
                <Icon
                  icon="InfoSquareRounded"
                  className={cn(
                    "h-4 w-4 ml-1 text-frGrey-400 hover:text-frGrey-300",
                    {
                      "text-frGrey-500 hover:text-frGrey-400": disabled,
                    }
                  )}
                />
              </TooltipTrigger>
              <TooltipContent className="bg-frGrey-800 border-frGrey-500 max-w-60 font-normal text-sm">
                <p>{tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </Label>
      <div className="relative">{children}</div>
      {error ? (
        <Label variant="body3" className="text-error text-xs">
          {error}
        </Label>
      ) : (
        <div className="flex-grow"></div>
      )}
    </div>
  );
};

export interface CalculatorInputProps
  extends Omit<InputWrapperProps, "children"> {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;

  suffix?: React.ReactNode;
  type?: string;

  inputProps?: Omit<React.ComponentProps<typeof Input>, "onChange">;

  variant?: "default" | "light";

  tabIndex?: number;
}

const calculatorInputVariants = cva(
  "[&::-webkit-outer-spin-button]:hidden [&::-webkit-inner-spin-button]:hidden",
  {
    variants: {
      variant: {
        default: "bg-frGrey-985 hover:bg-frGrey-790 focus:bg-frGrey-790",
        light: "bg-frGrey-790 hover:bg-frGrey-640 focus:bg-frGrey-640",
      },
      error: {
        true: "text-error placeholder:text-error border-error",
        false: "",
      },
    },

    compoundVariants: [
      {
        variant: "default",
        error: false,
        className: "border-frGrey-790",
      },
      {
        variant: "light",
        error: false,
        className: "border-frGrey-640",
      },
    ],

    defaultVariants: {
      variant: "default",
      error: false,
    },
  }
);

export const CalculatorInput = ({
  value,
  onChange,
  placeholder,
  suffix,
  type,

  inputProps,

  variant,
  tabIndex,

  ...wrapperProps
}: CalculatorInputProps) => {
  const { id, disabled, error, isRequired } = wrapperProps;

  const onChangeHandler = useMemo(
    () => (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <InputWrapper {...wrapperProps}>
      <Input
        {...inputProps}
        id={inputProps?.id ?? id}
        value={inputProps?.value ?? value}
        placeholder={inputProps?.placeholder ?? placeholder}
        disabled={inputProps?.disabled ?? disabled}
        required={inputProps?.required ?? isRequired}
        type={inputProps?.type ?? type}
        onChange={onChangeHandler}
        inputSize="xs"
        variant="filled"
        className={calculatorInputVariants({
          variant,
          error: !!error,
        })}
        tabIndex={tabIndex}
      />

      {suffix && (
        <div
          className={cn(
            "flex items-center absolute right-3 top-0 bottom-0 text-sm font-normal",
            {
              "text-frGrey-300": !disabled,
              "text-frGrey-500": disabled,
              "text-error": error,
            }
          )}
        >
          {suffix}
        </div>
      )}
    </InputWrapper>
  );
};

export const CustomArrow = (
  props: DropdownIndicatorProps<any, true> & {
    error?: boolean;
  }
) => {
  return (
    <Icon
      icon="CaretDownFilled"
      className={cn(
        "ml-2 h-4 w-4 shrink-0 text-[#E0E0E0] transition-transform duration-300 ease-in-out",
        {
          "transform rotate-180": props?.selectProps?.menuIsOpen,
          "text-error": !!props.error,
        }
      )}
    />
  );
};

const controlVariants = cva(
  "justify-between  text-[#E0E0E0] hover:text-[#E0E0E0] px-3 font-light border",
  {
    variants: {
      variant: {
        default: "py-1 text-sm rounded-full hover:bg-frGrey-790 ",
        light: "py-1 text-sm rounded-full hover:bg-frGrey-640 ",
        square: "rounded-lg text-base py-0.5",
      },
      error: {
        true: "text-error hover:text-error border-error",
        false: "",
      },
      focus: {
        true: "",
        false: "",
      },
    },
    compoundVariants: [
      {
        variant: "default",
        error: false,
        className: "border-frGrey-790",
      },
      {
        variant: "light",
        error: false,
        className: "border-frGrey-640",
      },
      {
        variant: "square",
        error: false,
        className: "border-frGrey-800 border-white/30",
      },
      {
        variant: "default",
        focus: true,
        className: "bg-frGrey-790",
      },
      {
        variant: "default",
        focus: false,
        className: "bg-frGrey-985",
      },
      {
        variant: "light",
        focus: true,
        className: "bg-frGrey-640",
      },
      {
        variant: "light",
        focus: false,
        className: "bg-frGrey-790",
      },
    ],
    defaultVariants: {
      variant: "default",
      error: false,
      focus: false,
    },
  }
);

const menuVariants = cva("min-w-[300px] truncate z-[41]", {
  variants: {
    variant: {
      default: "bg-frGrey-790",
      light: "bg-frGrey-640",
      square: "bg-frGrey-800",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const noOptionsMessageVariants = cva("text-[#E0E0E0] px-3 py-1", {
  variants: {
    variant: {
      default: "bg-frGrey-800",
      light: "bg-frGrey-700",
      square: "bg-frGrey-800",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface CalculatorDropdownProps {
  label: string | React.ReactNode;

  choices: Array<{ value: string; label: string }>;

  value: { value: string; label: string } | null;
  setValue: (value: { value: string; label: string }) => void;

  placeholder?: string;
  emptyText?: string;
  labelClassName?: string;
  plainLabel?: boolean;

  variant?: "default" | "light" | "square";

  isRequired?: boolean;

  error?: string;

  isLoading?: boolean;

  id?: string;
  className?: string;

  tabIndex?: number;

  noClear?: boolean;
}

export const CalculatorDropdown = ({
  choices,

  value,
  setValue,

  id,
  label,

  placeholder = "Select...",
  emptyText = "No results found.",

  labelClassName,
  plainLabel,

  isLoading,

  error,

  isRequired,

  variant = "default",
  className,
  tabIndex,
  noClear,
}: CalculatorDropdownProps) => {
  return (
    <InputWrapper
      id={id}
      isRequired={isRequired}
      label={label}
      error={error}
      labelClassName={labelClassName}
      plainLabel={plainLabel}
      className={className}
    >
      <Select
        value={value}
        onChange={(option: { value: string; label: string }) =>
          setValue(option)
        }
        options={choices}
        label={label}
        placeholder={placeholder}
        id={id}
        unstyled
        noOptionsMessage={() => emptyText}
        styles={{
          control: (provided: Record<string, any>) => ({
            ...provided,
            minHeight: "32px",
          }),
        }}
        isClearable={!noClear}
        classNames={{
          indicatorSeparator: () => "hidden",
          indicatorContainer: () => "p-0",
          container: () => "w-full",
          control: (state: { isFocused: boolean; menuIsOpen: boolean }) =>
            controlVariants({
              variant,
              error: !!error,
              focus: state.isFocused || state.menuIsOpen,
            }),
          menu: () => menuVariants({ variant }),
          noOptionsMessage: () => noOptionsMessageVariants({ variant }),
        }}
        components={{
          DropdownIndicator: (props: DropdownIndicatorProps<any, true>) => (
            <CustomArrow {...props} error={!!error} />
          ),
        }}
        tabIndex={tabIndex}
      />

      {isLoading ? (
        <Skeleton className="absolute left-0 right-0 top-0 bottom-0 w-full rounded-full" />
      ) : null}
    </InputWrapper>
  );
};
