import { isSet } from "./form";
import { CalculatorModeString } from "./types";

export const valueSuffixConstructor = (
  value: string | number,
  suffix: string,
  space = true,
  suffixClassName = ""
) => {
  return (
    <span>
      {value}
      {space ? " " : ""}
      <span className={suffixClassName} aria-hidden>{`${suffix}`}</span>
    </span>
  );
};

export const asNumber = (params: URLSearchParams, key: string) => {
  const value = params.get(key);

  if (!isSet(value)) {
    return undefined;
  }

  return parseFloat(value);
};

export const parseMode = (mode: string | null) => {
  switch (mode?.toLowerCase()) {
    case "advanced":
      return CalculatorModeString.Advanced;

    default:
    case "simple":
      return CalculatorModeString.Simple;
  }
};
