import { RefObject, useCallback, useEffect } from "react";

export const useKeyboardSubmit = (
  formRef: RefObject<HTMLFormElement>,
  formVisible: boolean
) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter" && formVisible) {
        formRef?.current?.requestSubmit();
      }
    },
    [formVisible]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);
};
