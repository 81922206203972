import { Fragment, useCallback, useContext, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  CalcContext,
  convertCbftToHectoliter,
} from "@copmer/calculator-widget";

import { Commodity, Port, SimpleCalculatorFormData } from "./types";
import Icon from "./Icon";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { MultiModeInput } from "./schema";
import { CopyButton } from "./CopyButton";

const VoyageList = ({ ports }: { ports: string[] }) => {
  return (
    <div className="flex flex-wrap flex-row gap-2 items-center gap-y-0">
      {ports.map((port, index) => {
        return (
          <Fragment key={index}>
            <div className="text-sm text-white/[.5] max-md:text-white/60">
              {port}
            </div>
            {index !== ports.length - 1 ? (
              <Icon
                icon="ArrowRight"
                className="h-4 w-4 text-white/[.75] max-md:text-white/60"
              />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
};

const isMultiModeForm = (
  value: any,
  type: "single" | "multi"
): value is UseFormReturn<MultiModeInput> => {
  return type === "multi";
};

interface CalculationLookupSummaryProps {
  form: UseFormReturn<SimpleCalculatorFormData> | UseFormReturn<MultiModeInput>;

  commodities: Commodity[];
  ports: Port[];

  onStartEditing: () => void;

  isMobile?: boolean;

  setSortModalOpen?: (isOpen: boolean) => void;
  setFilterModalOpen?: (isOpen: boolean) => void;

  formType?: "single" | "multi";
}

export default function CalculationLookupSummary({
  form,
  commodities,
  ports,
  onStartEditing,
  isMobile,
  setSortModalOpen,
  setFilterModalOpen,
  formType = "single",
}: CalculationLookupSummaryProps) {
  const ctx = useContext(CalcContext);

  let commodityNode = null;

  if (isMultiModeForm(form, formType)) {
    commodityNode = "Multiple commodities";
  } else {
    const commodity = form.watch("commodity");

    const commodityData = commodities.find((c) => c.commodity === commodity);

    commodityNode = (
      <>
        {commodityData?.commodity ?? commodity} (
        {commodityData?.stowageFactorCBFT} cbft/ mt,{" "}
        {convertCbftToHectoliter(commodityData?.stowageFactorCBFT ?? 0)} kg/hl)
      </>
    );
  }

  const portCodes = isMultiModeForm(form, formType)
    ? form.watch("ports").map((port) => port.port ?? "") ?? []
    : [form.watch("startPort.port"), form.watch("endPort.port")];

  const portList = useMemo(() => {
    return portCodes.map((portCode) => {
      return (
        ports.find((p) => p.unlocode === portCode)?.displayName ?? portCode
      );
    });
  }, [portCodes]);

  return (
    <div
      className={cn("py-4 flex flex-row", {
        "px-2": !isMobile,
        "px-4": isMobile,
      })}
    >
      <div role="button" title="Edit" onClick={onStartEditing}>
        <div className="text-white text-base font-light max-md:text-sm max-md:text-white/80">
          {commodityNode}
        </div>
        <VoyageList ports={portList} />
      </div>

      {isMobile ? <div className="flex-grow" /> : null}

      <div
        className={cn("flex items-center justify-center", {
          "pl-11 pr-2": !isMobile,
          "gap-1": isMobile,
        })}
      >
        {isMobile && !ctx?.isCFROptimizer ? (
          <Button
            variant="icon"
            size="icon"
            onClick={() => setSortModalOpen?.(true)}
          >
            <Icon icon="ArrowsSort" className="h-6 w-6" />
          </Button>
        ) : null}
        {isMobile && !ctx?.isCFROptimizer ? (
          <Button
            variant="icon"
            size="icon"
            onClick={() => setFilterModalOpen?.(true)}
          >
            <Icon icon="Filter" className="h-6 w-6" />
          </Button>
        ) : null}

        <Button
          variant="icon"
          size={isMobile ? "icon" : "iconText"}
          onClick={onStartEditing}
        >
          <Icon icon="Edit" className="h-6 w-6" />
          {isMobile ? null : <span className="ml-2">Edit</span>}
        </Button>

        {isMobile && !ctx?.isCFROptimizer ? <CopyButton /> : null}
      </div>
    </div>
  );
}
