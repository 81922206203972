"use client";

import { useCallback, useLayoutEffect, useState } from "react";

export const defaultHeight = 81;

export const useMeasureHeaderHeight = () => {
  const [height, setHeight] = useState<number>(defaultHeight);

  const updateHeight = useCallback(
    (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        setHeight(entry.target.clientHeight);
      }
    },
    [setHeight]
  );

  useLayoutEffect(() => {
    const header = document.getElementById("navbar");
    if (header) {
      const resizeObserver = new ResizeObserver(updateHeight);

      resizeObserver.observe(header);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return height;
};