import {
  formatNumberWithCommas,
  VoyageResult,
  SubActivityType,
} from "@copmer/calculator-widget";
import { DebugTableKeyVal } from "./DebugTable";
import { valueSuffixConstructor } from "./utils";
import { Fragment } from "react";
import { getSubActivityLabel } from "./labels";
import DottedLineImage from "./dotted-line.svg";
import Icon from "./Icon";

export const getCurrencySymbol = (currency: string) => {
  const currencyMap = {
    USD: "$",
    EUR: "€",
  };

  return currencyMap[currency as keyof typeof currencyMap] || currency;
};

type PortStageT = VoyageResult["debugData"]["stages"][0] & {
  portCallIndex: number | null;
};

const PortItem = ({
  stage,
  plan,
  isLastStage,
}: {
  stage: PortStageT;
  plan: VoyageResult;
  isLastStage?: boolean;
}) => {
  const portCallIndex = stage.portCallIndex;

  if (portCallIndex === null || portCallIndex === undefined) {
    return null;
  }

  const portCall = plan.debugData.portInfos[portCallIndex];

  if (!portCall) {
    return null;
  }

  const costSum = stage.subactivities.reduce(
    (acc, subactivity) => acc + subactivity.sumInDollars,
    0
  );

  return (
    <>
      {portCall.operations.map((operation, i) => (
        <Fragment key={i}>
          <div className="grid grid-cols-4 gap-y-2 gap-x-3 max-[400px]:grid-cols-2 max-[320px]:grid-cols-1 relative">
            {isLastStage && i === portCall.operations.length - 1 ? (
              <div className="w-6 absolute -left-9 bottom-0 bg-frGrey-990 top-0" />
            ) : null}

            <DebugTableKeyVal
              value={
                <>
                  {portCall.DisplayName}

                  <div
                    className="w-6 absolute -left-9 top-0 bg-frGrey-990 py-2"
                    title={`isLastStage: ${isLastStage}, i: ${i}, portCall.operations.length: ${portCall.operations.length}`}
                  >
                    <Icon
                      icon={operation.operation === "L" ? "Download" : "Upload"}
                      className="text-frGrey-300 h-6 w-6 -mt-2"
                    />
                  </div>
                </>
              }
              labelClassName="leading-5"
              valueClassName="relative"
              label={
                <>
                  {operation.operation === "L" ? "Load" : "Discharge"}{" "}
                  {formatNumberWithCommas(operation.amount)}
                  <br className="max-[400px]:hidden" />
                  <span className="max-[400px]:inline hidden"> </span>
                  {operation.commodity}
                </>
              }
              className="col-span-2 row-span-2 max-[400px]:col-span-1 max-[400px]:row-span-1"
              grow="end"
            />

            <DebugTableKeyVal value={operation.cadence} label="Cadence" />
            <DebugTableKeyVal value={operation.terms} label="Terms" />

            {operation.draft <= 25 ? (
              <DebugTableKeyVal
                value={valueSuffixConstructor(
                  formatNumberWithCommas(operation.draft),
                  "m"
                )}
                label="Draft"
              />
            ) : null}

            <DebugTableKeyVal
              value={valueSuffixConstructor(
                formatNumberWithCommas(operation.portDA),
                getCurrencySymbol(operation.portDACurrency)
              )}
              label="Port DA"
            />
          </div>
        </Fragment>
      ))}
    </>
  );
};

const SailingItem = ({
  stage,
  isLastStage,
}: {
  stage: VoyageResult["debugData"]["stages"][0];
  isLastStage?: boolean;
}) => {
  return (
    <>
      {stage.subactivities
        .filter(
          (item) =>
            ![
              SubActivityType.VesselHire,
              SubActivityType.EU_ETS,
              SubActivityType.TotalCost,
            ].includes(item.subActivityType as SubActivityType)
        )
        .map((subactivity, j) => (
          <div
            className="grid grid-cols-4 gap-y-2 gap-x-3 max-[400px]:grid-cols-1"
            key={subactivity.subactivity + j}
          >
            <DebugTableKeyVal
              value={
                <>
                  {subactivity.subactivity}

                  <div className="w-6 absolute -left-9 top-0 bg-frGrey-990 py-2">
                    <Icon
                      icon="Circle"
                      className="text-frGrey-300 h-6 w-6 -mt-2"
                    />
                  </div>
                </>
              }
              label={getSubActivityLabel(subactivity.subActivityType)}
              valueClassName="relative"
              className="col-span-3 max-[400px]:col-span-1"
            />

            <DebugTableKeyVal
              value={valueSuffixConstructor(
                formatNumberWithCommas(subactivity.sumInDollars),
                getCurrencySymbol("USD")
              )}
              label={<>&nbsp;</>}
            />
          </div>
        ))}
    </>
  );
};

export default function MobileDebugTable({ plan }: { plan: VoyageResult }) {
  const stages = plan.debugData.stages.filter(
    (stage) => stage.name !== "Generic"
  );

  return (
    <div className="flex flex-col gap-6 py-4 pl-7 relative">
      <div
        className="absolute top-5 bottom-4 -left-2 pointer-none w-6"
        style={{
          background: `url(${DottedLineImage.src}) repeat-y center top`,
        }}
      />
      {stages.map((stage, i) =>
        stage.name === "Port" ? (
          <PortItem
            key={i}
            stage={stage as PortStageT}
            plan={plan}
            isLastStage={i === stages.length - 1}
          />
        ) : stage.name.startsWith("Sailing") ? (
          <SailingItem
            key={i}
            stage={stage}
            isLastStage={i === stages.length - 1}
          />
        ) : null
      )}
    </div>
  );
}
