import { useCallback, useMemo } from "react";
import { CalculatorDropdown, CalculatorDropdownProps } from "./Inputs";

import { convertCbftToHectoliter } from "@copmer/calculator-widget";

export default function CommoditySelector({
  commodities,
  isLoading,
  value,
  error,
  setValue,
  variant,
  tabIndex,
}: {
  commodities: Array<{
    commodity: string;
    stowageFactorCBFT: number;
  }>;
  isLoading?: boolean;

  value: string;
  setValue: (value: string | null) => void;

  error?: string;

  variant?: CalculatorDropdownProps["variant"];

  tabIndex?: number;
}) {
  const onSetValue = useCallback(
    (value: { value: string; label: string } | null) => {
      setValue(value?.value ?? null);
    },
    [setValue]
  );

  const commodityOptions = useMemo(
    () =>
      commodities.map((commodity) => ({
        value: commodity.commodity,
        label: `${commodity.commodity} (${
          commodity.stowageFactorCBFT
        } cbft/mt, ${convertCbftToHectoliter(
          commodity.stowageFactorCBFT
        )} kg/hl)`,
      })),
    [commodities]
  );

  const selectedValue =
    commodityOptions.find((option) => option.value === value) ?? null;

  return (
    <CalculatorDropdown
      id={"commodity"}
      label="Commodity"
      isRequired
      placeholder="Commodity"
      choices={commodityOptions}
      value={selectedValue}
      setValue={onSetValue}
      emptyText="No results found."
      isLoading={isLoading}
      error={error}
      variant={variant}
      tabIndex={tabIndex}
    />
  );
}
