export const numericOnChange =
  (onChange: (value: any) => void) => (value: string) => {
    if (value === "" || typeof value === "undefined") {
      onChange("");
      return;
    }

    const cleanValue = value.replaceAll(",", ".").replaceAll(/[^\d.-]/g, "");

    if (cleanValue.endsWith(".")) {
      if (cleanValue.indexOf(".") !== cleanValue.lastIndexOf(".")) {
        return;
      }

      onChange(cleanValue);
      return;
    } else if (cleanValue.endsWith(".0")) {
      onChange(cleanValue);
      return;
    }

    onChange(cleanValue);
  };
