import * as React from "react";

import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";

const inputVariants = cva(
  "flex w-full rounded-md border ring-offset-background focus-visible:outline-none " +
    "focus-visible:ring-1 disabled:cursor-not-allowed",
  {
    variants: {
      variant: {
        outline:
          "text-neutral-950 border-input bg-transparent placeholder:text-neutral-900 " +
          "placeholder:text-opacity-60 file:border-0 file:bg-transparent focus-visible:ring-neutral-500 disabled:opacity-70",
        filled:
          "bg-frGrey-800 border-frGrey-800 text-[#E0E0E0] hover:bg-frGrey-800 hover:text-[#E0E0E0] rounded-full " +
          "focus-visible:ring-0 placeholder:text-[#E0E0E0] disabled:placeholder:text-frGrey-500 disabled:text-frGrey-500",
      },
      size: {
        xs: "px-3 py-1.25 font-light text-sm",
        sm: "px-5 py-2",
        md: "h-[48px] px-3 py-2 text-[14px] file:text-sm file:font-medium",
      },
    },
    defaultVariants: {
      variant: "outline",
      size: "md",
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: "outline" | "filled";
  inputSize?: "xs" | "sm" | "md";
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, inputSize, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, size: inputSize, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
