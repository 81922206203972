"use client";

import { Skeleton } from "@/components/ui/skeleton";
import { SelectOption } from "./types";
import { cn } from "@/lib/utils";
import Icon from "./Icon";
import { useEffect, useState } from "react";
import { isSet } from "./form";

const alphabet = "abcdefghijklmnopqrstuvwxyz";

const getValueToUse = (
  isLoading: boolean | undefined,
  value: number,
  freightRates: Record<string, number>,
  type: "min" | "max"
) => {
  if (
    !isNaN(value) &&
    value !== Infinity &&
    value !== -Infinity &&
    !isLoading
  ) {
    return value;
  }

  const values = Object.values(freightRates).filter(isSet);

  if (values.length === 0) {
    return 0;
  }

  return type === "min" ? Math.min(...values) : Math.max(...values);
};

export default function MonthSelector({
  maxValue,
  minValue,

  activeMonth,
  setActiveMonth,

  months,

  isLoading,

  freightRates,
  loading,
}: {
  maxValue: number;
  minValue: number;

  setActiveMonth: (month: string) => void;
  activeMonth: string;

  months: SelectOption[];

  isLoading?: boolean;

  // list of months that are loading data from the backend
  loading?: string[];

  // object with freight rates for each month (some may be missing as they are still loading)
  freightRates: Record<string, number>;
}) {
  const minWidth = months.length * 52 + (months.length - 1) * 16 + 40;

  const [prevFreightRates, setPrevFreightRates] = useState(freightRates);

  useEffect(() => {
    setPrevFreightRates((cur) => {
      return {
        ...cur,

        ...Object.fromEntries(
          Object.entries(freightRates).filter(([key, val]) => isSet(val))
        ),
      };
    });
  }, [freightRates]);

  // Use provided max value or the previous one to avoid flicker.
  //  note: we also use previous value if we are currently loading
  const minValueToUse = getValueToUse(
    isLoading,
    minValue,
    prevFreightRates,
    "min"
  );
  const maxValueToUse = getValueToUse(
    isLoading,
    maxValue,
    prevFreightRates,
    "max"
  );

  return (
    <div className="relative max-md:px-4">
      <div className="max-xl:overflow-x-auto">
        <div
          className={`border-b border-frGrey-650 gap-4 gap-y-0 grid grid-cols-${months.length} relative max-xl:min-w-[${minWidth}px] max-xl:pr-10`}
        >
          {months.map((month, i) => {
            const freightRate =
              freightRates[month.value] ?? prevFreightRates[month.value];
            const isMonthLoading = loading?.includes(month.value);

            return (
              <div
                key={month.value}
                className={`min-w-[52px] h-24 flex items-end justify-center cursor-pointer select-none peer/month${alphabet.charAt(
                  i
                )}`}
                onClick={() => {
                  if (!isMonthLoading) {
                    setActiveMonth(month.value);
                  }
                }}
                role="button"
              >
                <div
                  className={cn(
                    "border-t border-x border-frGrey-550 flex items-end justify-center rounded-t-lg w-full bg-white/5 text-base max-xl:text-sm font-light relative",
                    {
                      "bg-primary-200 border-primary-400 text-frGrey-980":
                        activeMonth === month.value,
                      "select-none": isMonthLoading,
                      "text-transparent": isMonthLoading && !freightRate,
                    }
                  )}
                  style={{
                    height: `calc(32px + ${
                      // use min and max value to determine the height of the bar in a range from 0 to 1
                      //  maxValue is considered 100% and minValue is considered 0%
                      (((freightRate ?? 0) - minValueToUse) /
                        (maxValueToUse - minValueToUse)) *
                      64
                    }px)`,
                    minHeight: "32px",
                  }}
                >
                  {isMonthLoading && !freightRate
                    ? "0.00"
                    : freightRate?.toFixed?.(2) ?? "-"}

                  {isMonthLoading && (
                    <Skeleton className="absolute left-0 right-0 top-0 bottom-0 w-full rounded-none rounded-t-lg border-transparent" />
                  )}
                </div>
              </div>
            );
          })}

          {months.map((month, i) => (
            <div
              key={month.value}
              className={`min-w-[84px] cursor-pointer text-white/70 hover:text-white/90 peer-hover/month${alphabet.charAt(
                i
              )}:text-white/90`}
              onClick={() => setActiveMonth(month.value)}
              role="button"
            >
              <div
                className={cn(
                  `flex items-center justify-center p-2 self-end text-base max-xl:text-xs font-light ` +
                    `cursor-pointer select-none`,
                  {
                    "text-primary-200": activeMonth === month.value,
                  }
                )}
              >
                {month.label}
              </div>
            </div>
          ))}

          {isLoading ? (
            <div className="absolute -bottom-px left-0 right-0 h-px linear-pure-progress bg-primary-200" />
          ) : null}
        </div>
      </div>

      <div className="xl:hidden absolute right-0 top-0 bottom-0 bg-red w-10 bg-gradient-to-l from-frGrey-1200 to-frGrey-1200/0 from-50% to-146%">
        <Icon icon="ChevronsRight" className="text-white/70 w-6 h-6" />
      </div>
    </div>
  );
}
