import { useContext, useRef, useState } from "react";
import {
  useAdminApiMutation,
  CalcContext,
  CalculationsCopyResponse,
} from "@copmer/calculator-widget";

import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import Icon from "./Icon";
import { useCalculatorCopyContext } from "./copy-context";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export const getCopyMonthLabel = (month: string | Date) => {
  const date = typeof month === "string" ? new Date(month) : month;

  return `${date.toLocaleString(undefined, {
    month: "short",
  })}-${date.getFullYear()}`;
};

export const CopyButton = ({ label = false }: { label?: boolean }) => {
  const ctx = useCalculatorCopyContext();

  const isLoading = ctx?.isLoading;
  const calculationsToCopy = ctx?.calculationsToCopy ?? [];

  const calcCtx = useContext(CalcContext);

  if (!calcCtx) {
    throw new Error("CopyButton must be used within a CalcContext");
  }

  const { trigger, isMutating } = useAdminApiMutation<CalculationsCopyResponse>(
    {
      url: calcCtx.apiRoutes.copyOutput,
      method: "POST",
    }
  );

  const { toast } = useToast();

  const [copyText, setCopyText] = useState<string | null>(null);

  const copyOutput = async () => {
    try {
      const response = await trigger({
        body: {
          calculationsIds: ctx.calculationsToCopy,
          selectedMonth: getCopyMonthLabel(ctx.activeMonth ?? new Date()),
        },
      });
      await navigator.clipboard.writeText(response.copyText);

      setCopyText(response.copyText);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error copying output to clipboard",
        description: error.message ?? "Something went wrong",
      });
    }
  };

  const triggerRef = useRef<HTMLButtonElement>(null);

  if (!ctx) {
    console.error(
      "CopyButton must be used within a CalculatorCopyContextProvider"
    );
    return null;
  }

  const disabled =
    isLoading ||
    calculationsToCopy.length === 0 ||
    calculationsToCopy.some((c) => !c || !c.planId);

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger
          onClick={(event) => event.preventDefault()}
          ref={triggerRef}
        >
          <Button
            type="button"
            variant="icon"
            size={label ? "iconText" : "icon"}
            disabled={isMutating || disabled}
            onClick={copyOutput}
          >
            <Icon icon="Copy" className="h-6 w-6" />
            {label ? <span className="ml-2">Copy</span> : null}
          </Button>
        </TooltipTrigger>
        <TooltipContent
          onPointerDownOutside={(event) => {
            if (triggerRef.current?.contains(event.target as any))
              event.preventDefault();
          }}
        >
          <p className="whitespace-pre">
            {copyText ? copyText : "Copy Calculator Output"}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
